import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useGet } from 'hooks/useGet';

const SdsDownload = ({ record }) => {
  const { trustedSource, revisions, documentID, translations } = record;
  const { getData } = useGet();
  const [loading, setLoading] = useState(false);

  const getIconColor = () => (trustedSource ? 'green' : 'blue');

  const handleDownload = async (fileHash) => {
    setLoading(true);
    try {
      const response = await getData(`/sds/${fileHash}`, { responseType: 'arraybuffer' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileHash}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } finally {
      setLoading(false);
    }
  };

  const renderDownloadIcon = () => {
    const hasRevisions = revisions && revisions.length > 0;

    return (
      <a href="#" onClick={() => handleDownload(documentID)}>
        <span className="fa-2x fa-layers fa-fw">
          {hasRevisions && (
            <>
              <i className="fa-light fa-file" data-fa-transform="right-4" style={{ color: '#cecece' }} />
              <i className="fa-light fa-file" data-fa-transform="right-2" style={{ color: '#8a8a8a' }} />
            </>
          )}
          <i
            className="fa-duotone fa-solid fa-file"
            style={{ '--fa-primary-color': '#ffffff', '--fa-secondary-color': '#ffffff', '--fa-secondary-opacity': 1 }}
          />
          <i className="fa-light fa-file" style={{ color: getIconColor() }} />
          <i className="fa-kit fa-acrobat" data-fa-transform="shrink-8 down-1" style={{ color: getIconColor() }} />
          <i className="fas fa-circle" data-fa-transform="shrink-6 down-6 right-6" style={{ color: '#fff' }} />
          <i
            className={`fas fa-arrow-alt-circle-down ${loading ? 'd-none' : ''}`}
            data-fa-transform="shrink-8 down-6 right-6"
            style={{ color: '#000' }}
          />
          <i
            className={`fa-solid fa-gear fa-spin ${loading ? '' : 'd-none'}`}
            data-fa-transform="shrink-8 down-6 right-6"
            style={{ color: '#000' }}
          />
        </span>
      </a>
    );
  };

  const renderTranslationIcons = () => {
    return translations.map((translation) => (
      <a key={translation.language} href="#" onClick={() => handleDownload(translation.fileHash)}>
        <span className="fa-2x fa-layers fa-fw">
          <i
            className="fa-duotone fa-solid fa-file"
            style={{ '--fa-primary-color': '#ffffff', '--fa-secondary-color': '#ffffff', '--fa-secondary-opacity': 1 }}
          />
          <i className="fa-light fa-file" style={{ color: '#000000' }} />
          <span className="fa-layers-text" style={{ fontSize: '.85rem', color: '#000000' }}>
            {translation.language}
          </span>
          <i className="fas fa-circle" data-fa-transform="shrink-6 down-6 right-6" style={{ color: '#fff' }} />
          <i className="fas fa-arrow-alt-circle-down" data-fa-transform="shrink-8 down-6 right-6" style={{ color: '#000' }} />
        </span>
      </a>
    ));
  };

  return (
    <div>
      {renderDownloadIcon()}
      {renderTranslationIcons()}
    </div>
  );
};

SdsDownload.propTypes = {
  record: PropTypes.shape({
    trustedSource: PropTypes.bool.isRequired,
    revisionProperties: PropTypes.array.isRequired,
    canonical: PropTypes.object.isRequired,
    translations: PropTypes.arrayOf(
      PropTypes.shape({
        langCode: PropTypes.string.isRequired,
        fileHash: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default SdsDownload;

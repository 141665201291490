import React, { useState, useEffect } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@awesome.me/kit-75da6535ae/icons/classic/solid';

const ColumnVisibilityToggle = ({ table }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  let timeoutId;

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setShowDropdown(false);
    }, 3000);
  };

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
  };

  const handleToggle = (isOpen) => {
    setShowDropdown(isOpen);
    if (!isOpen) {
      clearTimeout(timeoutId);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Dropdown show={showDropdown} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onToggle={handleToggle}>
      <Dropdown.Toggle variant="secondary" id="dropdown-column-visibility">
        <FontAwesomeIcon icon={faListCheck} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {table
          .getAllLeafColumns()
          .filter((column) => column.id !== 'select') // Exclude the select column
          .map((column) => (
            <Dropdown.Item
              key={column.id}
              as="label"
              className="d-flex align-items-center"
              onClick={(e) => {
                e.preventDefault(); // Prevent default action
                e.stopPropagation(); // Prevent dropdown from closing
                column.getToggleVisibilityHandler()(e);
              }}
            >
              <Form.Check
                type="checkbox"
                checked={column.getIsVisible()}
                onChange={(e) => {
                  e.stopPropagation(); // Prevent dropdown from closing
                  column.getToggleVisibilityHandler()(e);
                }}
                label={column.columnDef.header}
              />
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ColumnVisibilityToggle;

import React, { useState, useEffect } from 'react';
import { useReactTable, getCoreRowModel, getPaginationRowModel } from '@tanstack/react-table';
import { Table } from 'react-bootstrap';
import { getColumnDefinitions } from './columnDefinitions';
import ColumnVisibilityToggle from './ColumnVisibilityToggle';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import PaginationComponent from '../sub-components/Pagination';
import './LifecycleTable.css'; // Import the CSS file

const LifecycleTable = ({ data = [], tableType, pageIndex, pageSize, setPageIndex, setPageSize, portalConfigs, onAddFilter }) => {
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});

  const pagination = { pageIndex, pageSize };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPageIndex(0);
  };

  const handlePageChange = (newPageIndex) => {
    setPageIndex(newPageIndex);
  };

  const columns = getColumnDefinitions(tableType, portalConfigs, onAddFilter);

  const table = useReactTable({
    autoResetPageIndex: false,
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      rowSelection,
      pagination: {
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      },
      columnVisibility,
    },
    onRowSelectionChange: setRowSelection,
    onPaginationChange: ({ pageIndex, pageSize }) => {
      setPageIndex(pageIndex);
      setPageSize(pageSize);
    },
    onColumnVisibilityChange: setColumnVisibility,
    enableRowSelection: true,
    pageCount: Math.ceil((data?.length || 0) / pageSize),
  });

  // Render a loading state or empty message if data is undefined or empty
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div className="table-wrapper">
      <div className="table-container">
        <div className="d-flex justify-content-end mb-2">
          <ColumnVisibilityToggle table={table} />
        </div>

        <Table striped bordered hover responsive>
          <TableHeader headerGroups={table.getHeaderGroups()} />
          <TableBody rows={table.getRowModel().rows} />
        </Table>

        <div className="sticky-pagination">
          <PaginationComponent
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            pageSize={table.getState().pagination.pageSize}
            totalEntries={data.length}
            onPageChange={(pageIndex) => handlePageChange(pageIndex)}
            previousPage={() => handlePageChange(table.getState().pagination.pageIndex - 1)}
            nextPage={() => handlePageChange(table.getState().pagination.pageIndex + 1)}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};

export default LifecycleTable;
